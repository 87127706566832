import React from 'react';

import {PersonalQuestionOneScenario} from './scenario/personal/PersonalQuestionOneScenario'
import {PersonalQuestionTwoScenario} from './scenario/personal/PersonalQuestionTwoScenario'
import {PersonalQuestionThreeScenario} from './scenario/personal/PersonalQuestionThreeScenario'
import {PersonalQuestionFourScenario} from './scenario/personal/PersonalQuestionFourScenario'
import {PersonalQuestionFiveScenario} from './scenario/personal/PersonalQuestionFiveScenario'
import {PersonalQuestionSixScenario} from './scenario/personal/PersonalQuestionSixScenario'
import {PersonalQuestionSevenScenario} from './scenario/personal/PersonalQuestionSevenScenario'


import {BusinessQuestionOneScenario} from './scenario/business/BusinessQuestionOneScenario'
import {BusinessQuestionTwoScenario} from './scenario/business/BusinessQuestionTwoScenario'
import {BusinessQuestionThreeScenario} from './scenario/business/BusinessQuestionThreeScenario'
import {BusinessQuestionFourScenario} from './scenario/business/BusinessQuestionFourScenario'
import {BusinessQuestionFiveScenario} from './scenario/business/BusinessQuestionFiveScenario'
import {BusinessQuestionSixScenario} from './scenario/business/BusinessQuestionSixScenario'

const scenarioMap={
    p1: PersonalQuestionOneScenario,
    p2: PersonalQuestionTwoScenario,
    p3: PersonalQuestionThreeScenario,
    p4: PersonalQuestionFourScenario,
    p5: PersonalQuestionFiveScenario,
    p6: PersonalQuestionSixScenario,
    p7: PersonalQuestionSevenScenario,
    b1: BusinessQuestionOneScenario,
    b2: BusinessQuestionTwoScenario,
    b3: BusinessQuestionThreeScenario,
    b4: BusinessQuestionFourScenario,
    b5: BusinessQuestionFiveScenario,
    b6: BusinessQuestionSixScenario
}

export default scenarioMap;