import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import BNZScamSavvyStepZero from './../BNZScamSavvyStepZero'

class BNZScamSavvyTCs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      next: false
    }
  }

  UNSAFE_componentWillMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
  }

  render() {
    let content = (
      <div className="modal-backdrop animated fadeIn">
        <div className="modal-container container">
          <div className="modal-content">
            <h1 className="text-center mb-3 mb-md-5">
              <Trans>Important Information</Trans>
            </h1>
            <p>
              <Trans>The information in BNZ Scam Savvy (Information) is provided for general purposes only. The Information is not intended to be a complete summary of how scams operate in New Zealand. If in doubt, you should contact BNZ for help or another trusted adviser.</Trans>
            </p>
            <p>
              <Trans>We might update the Information from time to time. The Information must not be used for any other purpose without BNZ’s prior written permission. No representation or warranty is made as to the accuracy, reliability or completeness of any Information. We don't accept any liability or responsibility for any loss you incur as a result of your use or any error or omission from the Information.</Trans>
            </p>
            <p>
              <Trans>References to third party websites are provided for your convenience only. We don’t accept any responsibility for the availability or contents of such websites.</Trans>
            </p>
            <p>
              <Trans>Please see our <a href="https://www.bnz.co.nz/support/security/security-online/our-privacy-policy" rel="noopener noreferrer" target="_blank">privacy policy</a>, which covers the information collected, used, shared and stored when you access BNZ Scam Savvy on our website.</Trans>
            </p>
            <div className="text-center mt-4">
              <button className="btn btn-primary" onClick={() => this.props.onTermsClose()}>
                <Trans>Continue</Trans> &gt; 
              </button>
            </div>
          </div>
        </div>
      </div>
    )
    if (this.state.next) content = <BNZScamSavvyStepZero scroll={false} />
    return content
  }
}

export default withTranslation()( BNZScamSavvyTCs )
