import React, { useEffect } from 'react'
import ReactGA from '../../util'
import { withTranslation, Trans } from 'react-i18next'
import SubHeader from '../sub-header/SubHeader' 
import i18n from '../../i18n' 
import HelpSection from '../help-section/index'

import scamSavvyPersonal from '../../assets/images/illustration-sam-the-scammer.svg'
import scamSavvyBusiness from '../../assets/images/business.svg'
import acertnzLogo from '../../assets/images/acertnz-logo.svg'
import {LearnMore }  from '../shared/buttons/BNZScamSavvyButtons'

function Home(){
  useEffect(() => {
    i18n.setDefaultNamespace("home")
  }, [])
  
    return (
      <>
        <div className="container-fluid bg-red">
          <SubHeader />
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 col-md">
              <a
                href="/scam-savvy-personal/"
                className="panel panel-1"
                onClick={() => ReactGA.event({ action: 'Click', category: 'Homepage', label: 'Personal' })}
              >
                <div className="scam-savvy">
                  <div className="row h-100 d-flex flex-column justify-content-between">
                    <h3 className="title mb-md-5">
                      <Trans>Helping you be safer online</Trans>
                    </h3>
                    <div>
                      <img src={scamSavvyPersonal} alt="Scam Savvy" className="my-4 mb-md-5 animated" width="171" />
                      <div className="scam-description">
                        <Trans>Learn to identify common scams, what to do when you spot them, and how to respond if you’ve been scammed.</Trans>
                      </div>
                    </div>
                    <LearnMore quizType="personal" />
                  </div>
                </div>
              </a>
            </div>
            <div className="col-12 col-md">
              <a
                href="/scam-savvy-business/"
                className="panel panel-2"
                onClick={() => ReactGA.event({ action: 'Click', category: 'Homepage', label: 'Business' })}
              >
                <div className="scam-savvy-2">
                  <div className="row h-100 d-flex flex-column justify-content-between">
                    <h3 className="title mb-md-5">
                      <Trans>Helping New Zealand businesses be safer online</Trans>
                    </h3>
                    <div>
                      <img src={scamSavvyBusiness} alt="Scam Savvy Mobile" className="my-4 mb-md-5 animated delay-1s fadeInUp" width="171" />
                      <div className="scam-description">
                        <Trans>
                          Learn to identify common scams that could affect you and your business, what to do when you spot them, and how to respond if
                          you’ve been scammed.
                        </Trans>
                      </div>
                    </div>
                    <button type="button" className="btn btn-outlined btn-white mx-auto mt-5">
                      <span className="mr-2">
                        <Trans>Learn more &gt;</Trans>
                      </span>
                    </button>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container">
            <HelpSection />
          </div>
        </div>
        <div className="bg-blue">
          <div className="container">
            <div className="blue-block">
              <h2 className="title mb-5">
                <Trans>What you should do if you think you’ve been scammed</Trans>
              </h2>
              <div className="row pt-md-5">
                <div className="col-12 col-md-4">
                  <div className="report-card">
                    <div className="badge">1</div>
                    <Trans>Contact your bank as soon as possible to let them know what’s happened.</Trans>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="report-card">
                    <div className="badge">2</div>
                    <Trans>Your bank may need to help you reset passwords, monitor your account for unusual transactions, or reissue cards.</Trans>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="report-card">
                    <div className="badge">3</div>
                    <Trans>
                      If the scammers have got hold of personal identification documents such as your driver’s licence or passport, contact the
                      police.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid white-block">
          <a
            href="https://www.cert.govt.nz/individuals/report-an-issue/"
            className="btn btn-outlined mx-auto px-3 px-md-4 mt-4 mt-md-5"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ReactGA.event({ action: 'Click', category: 'Homepage', label: 'ReportScam' })}
          >
            <Trans>Report an online scam &gt;</Trans>
          </a>
          <img src={acertnzLogo} alt="CERTNZ" className="mt-4" width="140" />
        </div>
      </>
    )
}


export default withTranslation()(Home)
