import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next';
import { sendGA } from '../../../components/questions/shared/state/actions'
import illustration from '../../../assets/images/illustration-business-building.svg'

class BNZScamSavvyResultsTwo extends Component {
  constructor(props) {
    super(props)
    this.track = this.track.bind(this)
  }

  track() {
    this.props.dispatch(sendGA('Click', 'BusinessResults', 'Personal'))
  }

  render() {
    const content = (
      <section id="results-two" className="results-container results-two bg-purple">
        <div className="content-wrapper centre-it">
          <div className="row main-row">
            <div className="col-12 col-md-6 main-row-pic">
              <div className="image-content mx-auto mb-5 mb-md-0">
                <Fade up>
                  <img className="img-fluid d-block mx-auto" src={illustration} width="277" />
                </Fade>
              </div>
            </div>
            <div className="col-12 col-md-6 main-row-body">
              <h2 className="color-white"><Trans>Helping New Zealand businesses stay safer online</Trans></h2>
              <div className="divider bg-white my-4 mx-auto ml-md-0" />
              <div className="intro-text color-white px-4 px-md-0 mb-5">
                <Trans>See if you can detect which of these examples are Sam the Scammer trying to deceive your business.</Trans>
              </div>
              <a href="/scam-savvy-business/" className="btn btn-primary btn-white" onClick={this.track}>
                <Trans>Business scams quiz &gt;</Trans>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyResultsTwo) )
