import React from 'react'
import ReactGA from '../../util'
import { Trans } from 'react-i18next'
import './help-section.scss'
import downloadIllustration from '../../assets/images/illustration-two-people.svg'

function HelpSection() {

  let content = (
    <div className="download-section-wrapper">
      <div className="row">
        <div className="col-12 col-md-5">
          <img src={downloadIllustration} className="img-fluid d-block mx-auto" alt="" />
        </div>
        <div className="col-12 col-md-7">
          <h2><Trans>Help others get Scam Savvy</Trans></h2>
          <div className="divider bg-blue my-4 mx-auto ml-md-0" />
          <div className="intro-text px-4 py-2 p-md-0">
            <p><Trans>If you know others who may be interested in learning how to identify and avoid scams, you can download our free information pack.</Trans></p>
          </div>
          <div>
            <a className="btn btn-outlined btn-blue mt-2"
              href="/scam-savvy-personal/?show=download"
              onClick={() => ReactGA.event({ action: 'Click', category: 'Homepage', label: 'Download for Personal' })}>
              <span className="mr-2"><Trans>Download for Personal &gt;</Trans></span>
            </a>
            <a className="btn btn-outlined btn-blue mx-auto mt-2"
              href="/scam-savvy-business/?show=download"
              onClick={() => ReactGA.event({ action: 'Click', category: 'Homepage', label: 'Download for Business' })}>
              <span className="mr-2"><Trans>Download for Business &gt;</Trans></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
  return content

}

export default HelpSection