import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendGA } from './../../questions/shared/state/actions'
import { withTranslation } from 'react-i18next';
import BNZScamSavvyResults from  './BNZScamSavvyResults'
import BNZScamSavvyResultsOne from './BNZScamSavvyResultsOne'
import BNZScamSavvyResultsTwo from './BNZScamSavvyResultsTwo'
import BNZScamSavvyResultsThree from './BNZScamSavvyResultsThree'
import BNZScamSavvyResultsFour from './BNZScamSavvyResultsFour'
import BNZScamSavvyResultsFive from './BNZScamSavvyResultsFive'
import BNZScamSavvyStepZero from '../../steps/BNZScamSavvyStepZero'
import BNZScamSavvyDownload from '../../steps/download/BNZScamSavvyDownload'

class BNZScamSavvyResultsZero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restart: false
    }
    this.onRestart = this.onRestart.bind(this)
    this.onClickDownload = this.onClickDownload.bind(this)
    this.onDownloadClose = this.onDownloadClose.bind(this)
  }

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  onRestart() {
    this.props.dispatch(sendGA('Click', 'PersonalResults', 'StartQuiz'))
    this.setState({
      restart: true
    })
  }

  onClickDownload() {
    this.setState({downloadModal: true})
    this.props.dispatch(sendGA('Click', 'PersonalResults', 'Download'))
  }

  onDownloadClose() {
    this.setState({downloadModal: false})
    this.props.dispatch(sendGA('click', 'PersonalDownload', 'Close'))
  }

  render() {
    const { downloadModal } = this.state
    let content = (
      <div className="container-fluid">
        <div className="row no-hor-scroll-wrapper">
          { downloadModal && <BNZScamSavvyDownload onDownloadClose={this.onDownloadClose} /> }
          <div className="col-sm-12 p-0">
            <BNZScamSavvyResults onClickReStart={this.onRestart} />
            <BNZScamSavvyResultsOne onClickDownload={this.onClickDownload} />
            <BNZScamSavvyResultsTwo />
            <BNZScamSavvyResultsThree />
            <BNZScamSavvyResultsFour />
            <BNZScamSavvyResultsFive />
          </div>
        </div>
      </div>
    )
    if (this.state.restart) content = <BNZScamSavvyStepZero scroll={true} />
    return content
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyResultsZero) )
