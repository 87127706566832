import React from 'react';
import { Fade } from 'react-reveal'
import personalQuestion1 from '../../../../assets/images/illustration-netflix.svg'
import personalQuestion2 from '../../../../assets/images/illustration-john.svg'
import personalQuestion3 from '../../../../assets/images/illustration-online-shopping.svg'
import personalQuestion4 from '../../../../assets/images/illustration-ird.svg'
import personalQuestion5 from '../../../../assets/images/illustration-courier.svg'
import personalQuestion6 from '../../../../assets/images/illustration-puppy-scam.svg'
import personalQuestion7 from '../../../../assets/images/illustration-mower-man.svg'

import businesslQuestion1 from '../../../../assets/images/illustration-mower-customer.svg'
import businessQuestion2 from '../../../../assets/images/illustration-ird.svg'
import businessQuestion3 from '../../../../assets/images/illustration-techhelp.svg'
import businessQuestion4 from '../../../../assets/images/illustration-accountant.svg'
import businessQuestion5 from '../../../../assets/images/illustration-telcoms.svg'
import businessQuestion6 from '../../../../assets/images/illustration-ceo.svg'

const svgID={
    p1: personalQuestion1,
    p2: personalQuestion2,
    p3: personalQuestion3,
    p4: personalQuestion4,
    p5: personalQuestion5,
    p6: personalQuestion6,
    p7: personalQuestion7,
    b1: businesslQuestion1,
    b2: businessQuestion2,
    b3: businessQuestion3,
    b4: businessQuestion4,
    b5: businessQuestion5,
    b6: businessQuestion6
}

export const DynamicSVG = ({ id }) => {
    const selectedSVG = svgID[id];
  
    if (!selectedSVG) {
      return null; 
    }
  
    return (
        <div className="image-content">
          <Fade bottom>
            <img src={selectedSVG} width="277" className="img-fluid" alt="step2" />
          </Fade>
        </div>
    );
  };

export default DynamicSVG;