import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import BNZQuestionStart from '../questions/BNZQuestionStart'
import loading from '../../assets/images/ss-loading.gif'


class BNZScamSavvyStepLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      next: false // set to FALSE
    }
  }

  UNSAFE_componentWillMount() {
    const self = this
    this.animation = setTimeout(() => {
      self.setState({
        next: true
      })
    }, 2500)
  }
  
  componentWillUnmount(){
    clearTimeout(this.animation)
  }

  render() {
    let content = (
      <div className="col-sm-12 p-0">
        <section id="step-loader" className="step-loading">
          <div className="content-wrapper centre-it loader-screen h-100">
            <div className="loader-content">
              <img src={loading} width="232" />
              <div className="intro-text"><Trans>Loading</Trans><br/><Trans>Scam Savvy...</Trans></div>
            </div>
          </div>
        </section>
      </div>
    )
    if (this.state.next) content = <BNZQuestionStart questionData={this.props.questionData} />
    return content
  }
}

export default withTranslation()( BNZScamSavvyStepLoading )
