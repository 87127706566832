
import { Trans } from 'react-i18next'
import { EmailMessage } from '../../../BNZStepCommon'
import download from '../../../../../../assets/images/icon-download-all.png'
import preview from '../../../../../../assets/images/icon-preview-all.png'
import bill from '../../../../../../assets/images/telcomms-bill-attachment.svg'
const emailMessage=(
    <div>
    <div className="px-4 pt-3 pb-2">
      <span><strong><Trans>Sender:</Trans></strong> TeleComms &lt;chromatography@smjournals.org&gt;</span>
      <br/>
      <span><strong><Trans>To:</Trans></strong> johnsmith@internetprovider.co.nz</span>
    </div>
    <hr/>
    <div className="px-4 py-2">
      <Trans>Here's this month's TeleComms bill</Trans>
    </div>
    <div className="px-4 py-2">
      <img src={bill} alt="" width="206" className="img-fluid" />
    </div>
    <div className="px-4 py-2">
      <span className="link mr-4"><img src={download} alt="" width="25" className="mr-2" /><small><Trans>Download All</Trans></small></span>
      <span className="link"><img src={preview} alt="" width="21" className="mr-2" /><small><Trans>Preview All</Trans></small></span>
    </div>
    <hr/>
    <div className="px-4 py-4">
      <p className="color-grey">
        <Trans>Account #7452225170</Trans>
      </p>
      <p className="color-grey">
        <Trans>Thanks for being with TeleComms.</Trans>
      </p>
      <p className="color-grey">
        <Trans>This email has been sent to all email addresses registered for online bill on this account.</Trans>
      </p>
    </div>
  </div>
)

export const BusinessQuestionFiveScenario = <EmailMessage value ={emailMessage}/>
