import ReactGA from '../../../../util'
const steps = ["Scenario","Question","Answer"]
export const initialState = {
  selectedAnswers: [],
  currentQuestion: {},
  currentStep: 0,
  alertExitPopupVisible: false,
  started: false,
  loadanimation: true,
  questionFinishing: false,
  questionCopyFinishing: false,
  answerFinishing: false,
  answerCopyFinishing: false,
  ga: { category: '', action: '', label: '' },
  questionNumber: 1,
  stepName:"Scenario",
  isScenario: true,
  isQuestion: false,
  isAnswer: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_QUESTION':
      return {
        ...state,
        questionNumber: state.questionNumber + 1,
      }
    case 'DECREMENT_QUESTION':

      return {
        ...state,
        currentStep: state.questionNumber >1 ? 2 : (state.currentStep >= 0 ? state.currentStep - 1 : state.currentStep),
        questionNumber: state.questionNumber > 0 ? state.questionNumber - 1 : 0,
      }
    case 'PUSH_SELECTED_ANSWER': {
      const everythingButCurrent = state.selectedAnswers.filter((item) => item.questionNumber !== action.selectedAnswer.questionNumber)
      return {
        ...state,
        selectedAnswers: [...everythingButCurrent, action.selectedAnswer],
      }
    }
    case 'SET_CURRENT_QUESTION':
      return {
        ...state,
        currentQuestion: action.question,
      }
    case 'CLEAR_ALL_ANSWERS':
      return {
        ...initialState,
        loadanimation: state.loadanimation,
      }
    case 'INIT_STEPS':
      return {
        ...state,
        started: true,
      }
    case 'ANIMATION_FINISHED':
      return {
        ...state,
        loadanimation: false,
      }
    case 'UPDATE_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.value,
      }
    case 'UPDATE_RESTART_POPUP':
      return {
        ...state,
        alertExitPopupVisible: action.value,
      }
    case 'INCREASE_CURRENT_STEP':
      const incrementedStepNumber=state.currentStep < 2 ?  state.currentStep + 1 : 0
      const higherStep=steps[incrementedStepNumber]
      return {
        ...state,
        stepName: steps[incrementedStepNumber],
        isScenario: higherStep === steps[0],
        isQuestion: higherStep === steps[1],
        isAnswer: higherStep === steps[2],
        currentStep: incrementedStepNumber,
      }
    case 'DECREASE_CURRENT_STEP':
      const decrementedNumber=state.currentStep >= 0 ? state.currentStep - 1 : state.currentStep
      const lowerStep=steps[decrementedNumber]
      return {
        ...state,
        stepName: lowerStep,
        isScenario: lowerStep === steps[0],
        isQuestion: lowerStep === steps[1],
        isAnswer: lowerStep === steps[2],
        currentStep: decrementedNumber,
      }
    case 'MARK_STEP_AS_STARTED_FINISHING': {
      const type = `${action.value}Finishing`
      const typeConent = `${action.value}CopyFinishing`
      return {
        ...state,
        [type]: true,
        [typeConent]: true,
      }
    }
    case 'MARK_STEP_AS_FINISHED_FINISHING': {
      const type = `${action.value}Finishing`
      return {
        ...state,
        [type]: false,
      }
    }
    case 'MARK_STEP_AS_COPY_CHANGED': {
      const typeConent = `${action.value}CopyFinishing`
      return {
        ...state,
        [typeConent]: false,
      }
    }

    case 'SEND_GA_EVENT': {
      let newGa = state.ga
      if (state.ga.action !== action.action || state.ga.label !== action.label || state.ga.category !== action.category) {
        ReactGA.event({ action: action.action, category: action.category, label: action.label })
        newGa = {
          category: action.category,
          action: action.action,
          label: action.label,
        }
      }

      return {
        ...state,
        ga: newGa,
      }
    }
    default:
      return state
  }
}