import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import Hamburger from '../hamburger'
import { sendGA } from './../questions/shared/state/actions'
import i18n from './../../i18n'
import bnzLogo from '../../assets/images/bnz-logo.svg'
import scamSavvy from '../../assets/images/scam-savvy.svg'
import iconLanguage from '../../assets/images/icon-language.svg'

class MenuHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: false,
      lang: localStorage.getItem('currLang') || 'en'
    }
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.track = this.track.bind(this)
  }

  componentDidMount() {
    const headerHeightHandler = () => {
      if (window.innerWidth > 1140) {
        if (window.pageYOffset > 110)
          document.getElementById('header-menu').style.height = "80px"
        else
          document.getElementById('header-menu').style.height = "110px"
      } else {
        if (window.pageYOffset > 74)
          document.getElementById('header-menu').style.height = "60px"
        else
          document.getElementById('header-menu').style.height = "74px"
      }
    }

    ['scroll', 'resize'].forEach(function (e) {
      window.addEventListener(e, () => { headerHeightHandler() })
    })
  }

  handleMobileMenu = (open) => {
    this.setState({ active: open })
    if (open === true) {
      document.body.style.overflow = 'hidden'
      document.getElementById('headerMobileDrawer').style.bottom = 0
      this.props.dispatch(sendGA('Click', 'HamburgerMenu', 'Opened'))
    } else {
      document.body.style.overflow = ''
      document.getElementById('headerMobileDrawer').style.bottom = ''
      this.props.dispatch(sendGA('Click', 'HamburgerMenu', 'Closed'))
    }
  }

  handleLanguageChange(e) {
    this.props.dispatch(sendGA('Select', 'Nav', 'Language-' + e.target.value))
    i18n.changeLanguage(e.target.value)
    localStorage.setItem('currLang', e.target.value)
    this.setState({ lang: e.target.value })
  }

  track(event, category, label) {
    this.props.dispatch(sendGA(event, category, label))
  }


  render() {
    let content = (
      
      <div>
        <header className="header-mobile-nav" id="headerMobileDrawer">
          <div className="header-mobile-nav-content">
            <ul className="header-menu mt-1">
              <li><a href="/" className= { this.props.pathname === "/"? "active" : "" } onClick={() => this.track('Click', 'Nav', 'Home')}><Trans>Home</Trans></a></li>
              <li><a href="/scam-savvy-personal/" className= { this.props.pathname === "/scam-savvy-personal/"? "active" : "" }   onClick={() => this.track('Click', 'Nav', 'Personal')}><Trans>Personal scams</Trans></a></li>
              <li><a href="/scam-savvy-business/"className= { this.props.pathname=== "/scam-savvy-business/"? "active" : "" }  onClick={() => this.track('Click', 'Nav', 'Business')}><Trans>Business scams</Trans></a></li>
              <li><a target='_blank' rel="noreferrer" href="https://www.bnz.co.nz/about-us/online-security/latest-scams" onClick={() => this.track('Click', 'Nav', 'Trending')}><Trans>Trending stories</Trans></a></li>
            </ul>
            <div className="row  header-mobile-nav-footer">
              <div className="col-6 d-flex align-items-center justify-content-start">
                <span>
                  <img src={iconLanguage} width="15" alt="Language" className="mb-1" />
                </span>
                <span className="ml-2">
                  <strong><Trans>Language</Trans></strong>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                <div className="lang-select-wrapper">
                  <select name="language" className="lang-select" onChange={(e) => this.handleLanguageChange(e)} value={this.state.lang}>
                    <option value="en">English</option>
                    <option value="mi_NZ">Te reo Māori</option>
                    <option value="smo">Samoan</option>
                    <option value="to">Tongan</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </header>
        <header className="header" id="header-menu">
          <div className="container">

            <div className="row header-nav-mobile">
              <div className="d-flex justify-content-end">
                <div>
                  <a href="https://www.bnz.co.nz/" target="_blank" rel="noopener noreferrer"
                    className="header-logo-wrapper"
                    onClick={() => this.track('Click', 'Nav', 'BNZLogo')}>
                    <img src={bnzLogo} alt="BNZ" className="header-logo" />
                  </a>
                </div>
              </div>
              <div className="px-4 d-flex align-items-center justify-content-start">
                <div>
                  <a href="/" onClick={() => this.track('Click', 'Nav', 'ScamSavvyLogo')}>
                    <img src={scamSavvy} width="208" className="get-scam-savvy" alt="Scam Savvy" />
                  </a>
                </div>
              </div>
              <div className="col d-flex align-items-center justify-content-end">
                <Hamburger
                  active={this.state.active}
                  type="3dy"
                  onClick={() => this.handleMobileMenu(!this.state.active)}
                />
              </div>
            </div>

            <div className="row header-nav">
              <div className="px-3 mr-3 d-flex align-items-center justify-content-start">
                <div>
                  <a href="/" onClick={() => this.track('Click', 'Nav', 'ScamSavvyLogo')}>
                    <img src={scamSavvy} width="208" className="get-scam-savvy" alt="Scam Savvy" />
                  </a>
                </div>
              </div>
              <div className="col d-flex align-items-center justify-content-between">
                <div>
                  <ul className="header-menu mt-1">
                    <li><a href="/"  className= { this.props.pathname=== "/"? "active" : "" } onClick={() => this.track('Click', 'Nav', 'Home')}><Trans>Home</Trans></a></li>
                    <li><a href="/scam-savvy-personal/" className= { this.props.pathname=== "/scam-savvy-personal/"? "active" : "" }  onClick={() => this.track('Click', 'Nav', 'Personal')}><Trans>Personal scams</Trans></a></li>
                    <li><a href="/scam-savvy-business/" className= { this.props.pathname=== "/scam-savvy-business/"? "active" : "" } onClick={() => this.track('Click', 'Nav', 'Business')}><Trans>Business scams</Trans></a></li>
                    <li><a target='_blank' rel="noreferrer" href="https://www.bnz.co.nz/about-us/online-security/latest-scams" onClick={() => this.track('Click', 'Nav', 'Trending')} ><Trans>Trending stories</Trans></a></li>
                  </ul>
                </div>
                <div className="mr-4 mb-1 d-flex">
                  <img src={iconLanguage} width="15" alt="Language" />
                  <div className="lang-select-wrapper">
                    <select name="language" className="lang-select" onChange={(e) => this.handleLanguageChange(e) } value={this.state.lang}>
                      <option value="en">English</option>
                      <option value="mi_NZ">Te reo Māori</option>
                      <option value="smo">Samoan</option>
                      <option value="to">Tongan</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="px-3 d-flex justify-content-end">
                <div>
                  <a href="https://www.bnz.co.nz/" target="_blank" rel="noopener noreferrer"
                    className="header-logo-wrapper"
                    onClick={() => this.track('Click', 'Nav', 'BNZLogo')}>
                    <img src={bnzLogo} alt="BNZ" className="header-logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(MenuHeader))
