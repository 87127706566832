import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import i18n from '../../../i18n'
import badge from '../../../assets/images/certified-badge.svg'

class BNZScamSavvyResults extends Component {

  getResults() {
    const selection = this.props.answerStore.selectedAnswers
    const correct = selection.filter(current => current.correct)
    return {
      correct: correct.length,
      total: selection.length
    }
  }

render() {
  const results = this.getResults()
  const content = (
    <section id="step-results" className="results-container step-results bg-purple">
      <div className="content-wrapper centre-it text-center">
        <div className="px-5">
          <Fade top>
            <img alt="" src={badge} width="330" className="img-fluid" />
          </Fade>
        </div>
        <div className="intro-text my-5 color-white">
          {results.correct <= 1 && <Trans>Maybe next time!</Trans>}
          {results.correct >= 2 && results.correct <= 3 && <Trans>Keep trying!</Trans>}
          {results.correct > 3 && <Trans>Nice work!</Trans>}
          <br/>
          {i18n.t('You outsmarted Sam the Scammer ' + results.correct + ' out of ' + results.total + ' times.')}
        </div>
        <button className="btn btn-primary btn-white d-block mx-auto mt-5" onClick={() => this.props.onClickReStart() }>
          <Trans>Try again &gt;</Trans>
        </button>
      </div>
    </section>
  )
  return content
}
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyResults) )
