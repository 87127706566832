import { Slide } from 'react-reveal'
import componentMap from './DynamicScenario'
import BNZQuestionList from '../BNZQuestionList'
import BNZAnswer from '../BNZAnswer'
import { Trans } from 'react-i18next'
import Fade from 'react-reveal/Fade'

export function RightPanel({questionNumberValue, step, questionData}) {
  let content;
  switch(step){
    case 0:
      content =
    <Slide bottom>
      {componentMap[questionData[questionNumberValue].QuestionId]}
    </Slide>
    break
    case 1: 
      content = 
      <Fade right>
        <div className="question-title">
          <span><Trans>{questionData[questionNumberValue].QuestionPrompt}</Trans>
          {questionData[questionNumberValue].QuestionPrompt1 != null ?  `<br/><Trans>${questionData[questionNumberValue].QuestionPrompt1}</Trans>` : ""}
          </span>
        </div>
        <form className="question-card">
          <BNZQuestionList value={questionData[questionNumberValue].QuestionSet} questionNumber={questionNumberValue} />
        </form>
    </Fade>
      break
    case 2: 
      content = <BNZAnswer value={questionData[questionNumberValue].QuestionSet} questionData={questionData}/>
      break
    default:
      content = <></>
  }
  content=<div
  id={`rightPanelContent`}
  className="right-panel-content">
  <div className="divider-wrapper">
    <div className="divider" />
  </div>
  {content}
</div>
  return( content);
}



