

import { Trans } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { EmailMessage } from '../../../BNZStepCommon'

export const EmailMessageContent = (
    <div className="px-3 py-4">
    <div className="mb-3">
      <span><strong><Trans>Sender:</Trans></strong> john@johnsmowing.co.nz</span>
      <br/>
      <span><strong><Trans>To:</Trans></strong> ricksmith@internetprovider.co.nz</span>
      <br/>
      <span><strong><Trans>Subject:</Trans></strong> <Trans>Latest invoice</Trans></span>
    </div>
    <p className="color-grey"><Trans>Hi there, latest invoice attached.</Trans> </p>
    <p className="color-grey"><Trans>Please note that I have recently changed banks so you will need to use the new details provided in the invoice for this one and all future payments.</Trans></p>
    <p className="color-grey"><Trans>Could you please pay this one urgently as the end of the tax year is approaching.</Trans></p>
    <p className="color-grey"><Trans>Regards</Trans><br/><Trans>John</Trans></p>
  </div>
  )


  export const PersonalQuestionSevenScenario = (
    <Fade bottom>
      <EmailMessage value={EmailMessageContent} />
    </Fade>
  )