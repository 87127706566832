
import { Trans } from 'react-i18next'
import { EmailMessage } from '../../../BNZStepCommon'
//import Fade from 'react-reveal/Fade'

const emailMessage=(
    <div className="px-4 pt-4 pb-5">
    <div className="py-3" style={{fontSize: "14px"}}>
      <div className="color-light-grey" style={{border: "1px solid #D2DDEA", padding: "0 15px"}}>
        <div className="row py-2 font-weight-bold bg-light-grey">
          <div className="col"><Trans>Issued</Trans></div>
          <div className="col"><Trans>Invoice No</Trans></div>
          <div className="col"><Trans>Due Date</Trans></div>
          <div className="col text-right"><Trans>Paid</Trans></div>
        </div>
        <div className="row py-2">
          <div className="col"><Trans>02 Sep 2020</Trans></div>
          <div className="col"><Trans>Inv-0342</Trans></div>
          <div className="col"><Trans>10 Oct 2020</Trans></div>
          <div className="col text-right"><Trans>$0.00</Trans></div>
        </div>
        <div className="row py-2 bg-lighter-grey">
          <div className="col"><Trans>05 Sep 2020</Trans></div>
          <div className="col"><Trans>Inv-0343</Trans></div>
          <div className="col"><Trans>10 Oct 2020</Trans></div>
          <div className="col text-right"><Trans>$0.00</Trans></div>
        </div>
        <div className="row py-2">
          <div className="col"><Trans>12 Sep 2020</Trans></div>
          <div className="col"><Trans>Inv-0344</Trans></div>
          <div className="col"><Trans>10 Oct 2020</Trans></div>
          <div className="col text-right"><Trans>$0.00</Trans></div>
        </div>
        <div className="row py-2 bg-lighter-grey">
          <div className="col"><Trans>12 Sep 2020</Trans></div>
          <div className="col"><Trans>Inv-0345</Trans></div>
          <div className="col"><Trans>10 Oct 2020</Trans></div>
          <div className="col text-right"><Trans>$0.00</Trans></div>
        </div>
        <div className="row py-3">
          <div className="col text-right"><strong><Trans>Total paid</Trans></strong>&nbsp;&nbsp;&nbsp; $0.00</div>
        </div>
      </div>
    </div>
  </div>
)

export const BusinessQuestionOneScenario = <EmailMessage value ={emailMessage}/>
