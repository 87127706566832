
import { Trans } from 'react-i18next'
import { EmailMessage } from '../../../BNZStepCommon'
import warning from '../../../../../../assets/images/icon-green-warning.svg'
const emailMessage=(
    <div>
    <div className="px-4 pt-3 pb-2">
      <span><strong><Trans>Sender:</Trans></strong> CEO@matrixengineering.co.nz</span>
      <br/>
      <span><strong><Trans>Reply to:</Trans></strong> CEO&lt;CEO@matrixengineering.interlink.com&gt;</span>
      <br/>
      <span><strong><Trans>To:</Trans></strong> Louise@matrixengineering.co.nz</span>
    </div>
    <hr/>
    <div className="px-4 py-2">
      <img src={warning} alt="" width="18" className="mr-3" /><Trans>URGENT Request</Trans>
    </div>
    <hr/>
    <div className="px-4 py-4">
      <p className="color-grey">
        <Trans>Louise,</Trans>
      </p>
      <p className="color-grey">
        <Trans>Our people have been working incredibly hard and I want to reward a few of our staff who have gone above and beyond for our customers and our business.</Trans>
      </p>
      <p className="color-grey">
        <Trans>I’d like to do this today and need $1000 iTunes cards urgently. Can you please arrange this and send me the codes off the back of the iTunes cards. I’ll take it from there.</Trans>
      </p>
      <p className="color-grey">
        <Trans>I will be unable to be contacted via phone as I am in meetings all day. This is urgent, please get this underway immediately.</Trans>
      </p>
      <p className="color-grey">
        <Trans>Thanks a lot, really appreciate everything you’re doing too.</Trans>
      </p>
      <p className="color-grey">
        <Trans>Genevieve Potter</Trans>
      </p>
      <p className="color-grey">
        <Trans>Chief Executive</Trans>
      </p>
    </div>
  </div>
)

export const BusinessQuestionSixScenario = <EmailMessage value ={emailMessage}/>
