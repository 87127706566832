import { Trans } from 'react-i18next'
import dog from '../../../../../../assets/images/dog-face.jpg'
import Fade from 'react-reveal/Fade'
import { EmailMessage } from '../../../BNZStepCommon'

export const EmailMessageContent = (
    <div className="p-4">
      <p className="color-grey">
        <Trans>Thank you for contact us. We have a few puppies you can chose from. Please have a look at the attached photo. The pup on the left is a girl, the others males. They are well house trained and all their vet shots are done.</Trans>
      </p>
      <p className="color-grey">
        <Trans>We have kids and other pets so they are well use to play and getting on with other animals.</Trans>
      </p>
      <p className="color-grey">
        <Trans>To make sure you don’t miss out, If you want to order a pup you can just pay $250 now and pay the rest when you get the puppy.</Trans>
      </p>
      <p className="color-grey">
        <Trans>When you have make the bank transfer we will send you a receipt and organise the pick up or sending the pup if you prefer. To keep puppies safe, no viewings.</Trans>
      </p>
      <p className="color-grey">
        <Trans>Please let us know as soon as possable which one you want so we can hold it for you.</Trans>
      </p>
      <p className="color-grey">
        <Trans>Thanks again</Trans><br/><Trans>Margret</Trans>
      </p>
      <img src={dog} alt="" width="247" />
    </div>
  )



export const PersonalQuestionSixScenario = (
  <Fade bottom>
    <EmailMessage value={EmailMessageContent} />
  </Fade>
)