import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import { sendGA } from './../questions/shared/state/actions'
import BNZScamSavvyTCs from './../steps/tcs/BNZScamSavvyTCs'
import logo from '../../assets/images/bnz-logo-blue.svg'

class Footer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tcs: false
    }
    this.track = this.track.bind(this)
    this.onTermsClose = this.onTermsClose.bind(this)
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal && this.props.modal === 'tcs') {
      this.setState({ tcs: true })
    }
  }

  track(event, category, label) {
    this.props.dispatch(sendGA(event, category, label))
  }

  onTermsClose() {
    this.props.dispatch(sendGA('Click', 'ImportantInfo', 'Continue'))
    this.setState({ tcs: false })
  }

  onTermsClick() {
    this.props.dispatch(sendGA('Click', 'Footer', 'ImportantInfo'))
    this.setState({ tcs: true })
  }

  render() {
    const { tcs } = this.state;
    let content = (
      <div>
        {tcs && <BNZScamSavvyTCs onTermsClose={this.onTermsClose} />}
        <footer className="footer">
          <div className="container">
            <div className="row no-gutters mx-4 mx-md-0 flex-column-reverse flex-md-row">
              <div className="col-12 col-md-5 mt-5 mt-md-0 d-flex flex-column justify-content-start">
                <span className="brought-by"><Trans>Brought to you by</Trans></span>
                <a href="https://www.bnz.co.nz/" target="_blank" rel="noopener noreferrer" title="BNZ" onClick={() => this.track('Click', 'Footer', 'BNZLogo')}>
                  <img src={logo} className="mx-0" width="77" alt="BNZ" />
                </a>
              </div>
              <div className="col-12 col-md mb-5 mb-md-0 d-flex flex-row-reverse flex-md-row justify-content-end align-items-md-end">
                <div className="footer-menu d-flex flex-column-reverse flex-md-row mb-4 mb-md-0">
                  <a href="https://www.cert.govt.nz/individuals/report-an-issue/" className="link my-3 my-md-0 mr-md-5" target="_blank" rel="noopener noreferrer" onClick={() => this.track('Click', 'Footer', 'ReportScam')}>
                    <Trans>Report a scam</Trans>
                  </a>
                  <span className="link mb-3 mb-md-0" onClick={() => this.onTermsClick()}><Trans>Important information</Trans></span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(Footer))