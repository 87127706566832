import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import axios from 'axios'
import { connect } from 'react-redux'
import { sendGA } from '../../questions/shared/state/actions'

class BNZScamSavvyDownload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadLanguage: '',
      name: '',
      number: '',
      submitClicked: false,
      downloadUrl: ''
    }
    this.renderDownloadLanguageOptions = this.renderDownloadLanguageOptions.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.downloadLink = React.createRef()
    this.downloadData = [
      { label: 'English', value: 'en', url: 'Scam Savvy Week - Community Presentation ENGLISH.pdf' },
      { label: 'Te reo Māori', value: 'mi_NZ', url: 'Scam Savvy Week - Community Presentation MAORI.pdf' },
      { label: 'Samoan', value: 'smo', url: 'Scam Savvy Week - Community Presentation SAMOAN.pdf' },
      { label: 'Tongan', value: 'to', url: 'Scam Savvy Week - Community Presentation TONGAN.pdf' },
      { label: 'Hindi', value: 'hi', url: 'Scam Savvy Week - Community Presentation HINDI.pdf' },
      { label: 'Korean', value: 'ko', url: 'Scam Savvy Week - Community Presentation KOREAN.pdf' },
      { label: 'Mandarin', value: 'cmn', url: 'Scam Savvy Week - Community Presentation MANDARIN.pdf' },
      { label: 'Punjabi', value: 'pa', url: 'Scam Savvy Week - Community Presentation PUNJABI.pdf' }
    ]
  }

  UNSAFE_componentWillMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
  }

  handleChange(event) {
    const inputValue = event.target.value
    const stateField = event.target.name
    this.setState({
      [stateField]: inputValue
    })

    if (stateField === 'downloadLanguage') {
      const language = this.downloadData.find(data => data.value === inputValue)
      this.setState({ downloadUrl: language ? `/downloads/${language.url}` : '#' })
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { downloadLanguage, name, number } = this.state

    this.setState({ submitClicked: true })

    if (downloadLanguage === '' || name === '' || number === '' || number === '0') return

    this.props.dispatch(sendGA('Click', 'PersonalDownload', 'Download'))

    await axios
      .post(
        'https://7ab9709xn2.execute-api.ap-southeast-2.amazonaws.com/prod/BNZ-Scam-Savvy-API-Lambda',
        { personalLanguage: downloadLanguage, personalWho: name, personalCount: number },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': '3WedJSQaKj2M8OKagi6Ul4qUnb6q2gaVa1R8y8fA'
          }
        }
      )
      .then(res => {

        if (res.status === 200) {
          this.props.dispatch(sendGA('PersonalDownload', 'Language', downloadLanguage))
          this.props.dispatch(sendGA('PersonalDownload', 'Who', name))
          this.props.dispatch(sendGA('PersonalDownload', 'HowMany', number))
          document.getElementById('download-one').style.display = 'none'
          document.getElementById('download-two').style.display = 'block'
          this.downloadLink.current.click()
        }
      })
  }

  renderDownloadLanguageOptions() {
    const options = [<option key={-1} disabled value="" label="-" />]
    for (const i in this.downloadData) {
      options.push(<option key={i} value={this.downloadData[i].value} label={this.props.i18n.t(this.downloadData[i].label)}>{this.props.i18n.t(this.downloadData[i].label)}</option>)
    }

    return options
  }

  render() {
    let content = (
      <div className="modal-backdrop animated fadeIn">
        <div className="modal-container container download-container">
          <div className="modal-content" id="download-one">
            <i
              className="close fa fa-times color-dark-blue"
              onClick={() => {
                this.props.onDownloadClose()
              }}
            ></i>
            <h1 className="text-center mb-3 mb-md-5">
              <Trans>Help your community get Scam Savvy with this information pack</Trans>
            </h1>
            <div className="mx-auto" style={{ maxWidth: '590px' }}>
              <p className="text-center">
                <Trans>
                  We want to help all New Zealanders be safer online so this information pack is available for anyone to use and share with groups
                  they work with like community associations, sports clubs, book clubs, young farmers, neighbourhood watch, Probus, and church groups.
                </Trans>
              </p>
              <p className="text-center">
                <Trans>
                  To help us understand how widely this information is being shared, please answer these two simple questions about where you’re using
                  it:
                </Trans>
              </p>
            </div>
            <h2 className="text-center mt-5">
              <Trans>We’d love to know more about you</Trans>
            </h2>
            <div className="mx-auto mt-4" style={{ maxWidth: '500px' }}>
              <form onSubmit={this.handleSubmit}>
                <p className="form-group mb-5">
                  <label htmlFor="downloadLanguage">
                    <Trans>Select your language</Trans>:
                  </label>
                  <select name="downloadLanguage" className="form-control" onChange={this.handleChange} value={this.state.downloadLanguage}>
                    {this.renderDownloadLanguageOptions()}
                  </select>
                  {this.state.submitClicked && this.state.downloadLanguage === '' && (
                    <small className="color-red">
                      <Trans>Please select your language</Trans>
                    </small>
                  )}
                </p>

                <p className="form-group mb-5">
                  <label htmlFor="name">
                    <Trans>Who are you going to share this pack with?</Trans>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder={this.props.i18n.t('Organisation name')}
                    style={{ maxWidth: '493px' }}
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                  {this.state.submitClicked && this.state.name === '' && (
                    <small className="color-red">
                      <Trans>Please fill this field</Trans>
                    </small>
                  )}
                </p>
                <p className="form-group">
                  <label htmlFor="number">
                    <Trans>How many people will you share this pack with?</Trans>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="number"
                    id="number"
                    min="0"
                    max="9999"
                    step="1"
                    placeholder="0"
                    style={{ maxWidth: '168px' }}
                    onChange={this.handleChange}
                    value={this.state.number}
                  />
                  {this.state.submitClicked && (this.state.number === '' || this.state.number === '0') && (
                    <small className="color-red">
                      <Trans>Please provide an approximate number</Trans>
                    </small>
                  )}
                </p>
                <div className="text-center mt-5">
                  <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
                    <Trans>Download</Trans>
                    <span className="d-inline-block rotate-45 ml-2">&gt;</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-content animated fadeIn" id="download-two" style={{ display: 'none' }}>
            <i
              className="close fa fa-times color-dark-blue"
              onClick={() => {
                this.props.onDownloadClose()
              }}
            ></i>
            <h1 className="text-center mb-3 mb-md-5">
              <i className="fas fa-check color-gold" />
              <br />
              <Trans>Download started</Trans>
            </h1>
            <p className="text-center">
              <a
                href={this.state.downloadUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
                ref={this.downloadLink}
                onClick={() => this.props.dispatch(sendGA('Click', 'DownloadStarted', 'ForceDownload'))}
              >
                <Trans>Click here</Trans>
              </a>{' '}
              <Trans>if your download didn't automatically start.</Trans>
            </p>

            <div className="text-center mt-5">
              <button className="btn btn-primary" onClick={() => this.props.onDownloadClose()}>
                <Trans>Okay</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
    return content
  }
}

export default withTranslation()(
  connect(state => ({
    answerStore: state['bnz-scam-savvy-answer-list']
  }))(BNZScamSavvyDownload)
)
