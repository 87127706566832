import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { connect } from 'react-redux'

class BNZScamSavvyVideo extends Component {

  UNSAFE_componentWillMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
  }

  render() {
    let content = (
      <div className="modal-backdrop animated fadeIn">
        <div className="modal-container container video-container">
          <div className="modal-content p-5" id="video-one">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/s7RgqpS-Dw4" title="Video player" allowFullScreen></iframe>
            </div>
            <div className="text-center mt-5">
              <button className="btn btn-primary" onClick={() => this.props.onVideoClose()}>
                <Trans>Go back &gt;</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyVideo))
