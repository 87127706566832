import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import illustration from '../../../assets/images/illustration-accountant.svg'

class BNZScamSavvyStepFour extends Component {
  render() {
    const content = (
      <section id="step4" className="step-four">
        <div className="content-wrapper centre-it">
          <div className="row main-row">
            <div className="col-12 col-md-7 main-row-body">
              <h2 className="color-dark-blue"><Trans>Can you spot a scam?</Trans></h2>
              <div className="divider bg-dark-blue my-4 mx-auto ml-md-0" />
              <div className="intro-text px-4 px-md-0">
                <Trans>Answer six multi-choice questions and see if you can detect which examples are Sam the Scammer at work.</Trans>
              </div>
              <button className="btn btn-primary btn-blue d-block mx-auto mt-5" onClick={() => this.props.onClickStart()}>
                <Trans>Start the quiz &gt;</Trans>
              </button>
            </div>
            <div className="col-12 col-md-5 main-row-pic">
              <div className="image-content mx-auto mb-5 mb-md-0">
                <Fade up>
                  <img alt="helping your business get scam savvy" className="img-fluid d-block mx-auto" src={illustration} width="277" />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepFour))
