import React, { Component } from 'react'
import ReactGA from './util'

import { withTranslation } from 'react-i18next'
import Header from './components/header/MenuHeader'
import Footer from './components/footer/Footer'
import ModalInfo from './components/modal-info/ModalInfo'
import Home  from './components/pages/Home'
import PersonalQuiz from './components/pages/PersonalQuiz'
import BusinessQuiz from './components/pages/BusinessQuiz'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import withRouter from './components/router/WithRouterProp'
class App extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      toggleModalInfo: this.props.router.location.pathname==="/" ? false: true
    }
    
    this.toggleModalInfo = this.toggleModalInfo.bind(this)
    
  }

  toggleModalInfo() {
    
    if (this.state.toggleModalInfo)
      ReactGA.event({ action: 'Click', category: 'ImportantInfo', label: 'Continue' })
    else
      ReactGA.event({ action: 'Click', category: 'Footer', label: 'ImportantInfo' })

    this.setState({ toggleModalInfo: !this.state.toggleModalInfo })
  }

  render() {
    return (
        <main>
          <Header pathname={this.props.router.location.pathname}  />
          <section className="content">
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
            <Routes>
              <Route path="/scam-savvy-personal/" element={<PersonalQuiz />} />
            </Routes>
            <Routes>
              <Route path='/scam-savvy-business/' element={<BusinessQuiz />} />
            </Routes>
            <Footer tcs={this.toggleModalInfo} />
          </section>
          {this.state.toggleModalInfo && <ModalInfo hideModalClick={this.toggleModalInfo} />}
        </main>
    )
  }
}
export default withTranslation()(withRouter(App))
