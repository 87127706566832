const ReactGM = {
  event: function (data) {
    if (data) {
      window.PageDataLayer.push({event: data});
    }
    return false;
  },
};

export default ReactGM;
