import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import BNZScamSavvyStepZero from '../steps/business/BNZScamSavvyStepZero'
import data from '../../questionaires/BusinessQuestionaire.json'
import i18n from '../../i18n'

function BusinessQuiz() {
  const [modal, setModal] = useState()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const modalName = queryParams.get('show')
    i18n.setDefaultNamespace("business")

    if (modalName) {
      setModal(modalName)
    } else {
      setModal("tcs")
    }
  }, [])

  return (
      <BNZScamSavvyStepZero modal={modal} questionData={data}/>
  )
}

export default withTranslation()(
    connect(state => ({
    answerStore: state['bnz-scam-savvy-answer-list']
  }))(BusinessQuiz)
)
