import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation} from 'react-i18next'
import i18n from '../../i18n'
import BNZScamSavvyStepZero from '../steps/BNZScamSavvyStepZero' 
import data from '../../questionaires/PersonalQuestionaire.json'


function BNZScamSavvyApp() {
  const [modal, setModal] = useState()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const modalName = queryParams.get('show')
    i18n.setDefaultNamespace("personal")

    if (modalName) {
      setModal(modalName)
    } else {
      setModal('tcs')
    }
  }, [])

  return (
      <BNZScamSavvyStepZero modal={modal} questionData={data} />
  )
}

export default withTranslation()(
  connect((state) => ({
    answerStore: state['bnz-scam-savvy-answer-list'],
  }))(BNZScamSavvyApp)
)
