import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'

class BNZScamSavvyStepOneAndAHalf extends Component {

  render() {
    const content = (
      <section id="step12" className="step-one-a-half bg-dark-blue">
        <div className="content-wrapper centre-it">
          <div className="row">
            <div className="col-12 col-md-7">
              <div>
                <h2 className="color-white mb-2 mb-md-0"><Trans>Help others get Scam Savvy</Trans></h2>
                <p className="color-white py-2">
                  <Trans>If you know others who may be interested in learning how to identify and avoid scams, you can download our free information pack.</Trans>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <button className="btn btn-primary btn-white mx-auto" onClick={() => this.props.onClickDownload()}>
              <Trans>Download</Trans><span> &gt;</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepOneAndAHalf))
