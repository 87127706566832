import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { withTranslation, Trans } from 'react-i18next'
import illustration from '../../../assets/images/illustration-courier.svg'

class BNZScamSavvyResultsOne extends Component {
  render() {
    const content = (
      <section id="results-one" className="results-container results-one">
        <div className="content-wrapper centre-it">
          <div className="row main-row">
            <div className="col-12 col-md-7 main-row-body">
              <h2 className="color-dark-blue"><Trans>Help others get Scam Savvy</Trans></h2>
              <div className="divider bg-dark-blue my-4 mx-auto ml-md-0" />
              <div className="intro-text px-4 px-md-0">
                <Trans>If you know others who may be interested in learning how to identify and avoid scams, you can download our free information pack.</Trans>
              </div>
              <button className="btn btn-primary mt-5" onClick={() => this.props.onClickDownload() }>
                <Trans>Download</Trans><span className="d-inline-block rotate-45 ml-2">></span>
              </button>
            </div>
            <div className="col-12 col-md-5 main-row-pic">
              <div className="image-content mx-auto mb-5 mb-md-0">
                <Fade up>
                  <img className="img-fluid d-block mx-auto" src={illustration} width="277" />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( BNZScamSavvyResultsOne )
