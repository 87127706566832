import { Trans } from 'react-i18next'
import icon from '../../../../../../assets/images/icon-kiwi.svg'
import Fade from 'react-reveal/Fade'
import { EmailMessage } from '../../../BNZStepCommon'

const EmailMessageContent = (
    <div className="px-3 py-4">
      <div className="d-flex align-items-center mb-4">
        <span className="d-flex align-items-center justify-content-center rounded-circle bg-gold mr-3" style={{width: "60px", height: "60px"}}>
          <img src={icon} alt="" width="40" />
        </span>
        <span style={{color: "#2F80ED", fontWeight: 700, fontSize: "18px"}}>
          <strong><Trans>Kiwi Safety Company</Trans></strong>
        </span>
      </div>
      <p className="color-grey">
        <Trans>Kiwi Safety Company: Selling for cheap as a cancelled order. Hospital grade personal protection equipment that you can’t find in the supermarket. Keep you and your family safe from COVID-19. Usually retail at $39 per pack, selling for $19 per pack, get in quick as there will be plenty of interest. Message me and I’ll send you my details.</Trans>
      </p>
    </div>
  )


  export const PersonalQuestionThreeScenario =(
    <Fade bottom>
      <EmailMessage value={EmailMessageContent} />
    </Fade>
  )