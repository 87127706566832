import { Trans } from 'react-i18next'
import { ContentMessage } from '../../../BNZStepCommon'
import { Fade } from 'react-reveal'

const CallMessageContent = (
    <div>
    <p>
      <span className="fas fa-quote-left quote mr-2" /> 
        <Trans>Press 1 if this was you OR press 2 if this was not you.</Trans> 
      <span className="fas fa-quote-right quote ml-2" />
    </p>
    <p>
      <small><Trans>The call is then transferred to a representative from the provider:</Trans></small>
    </p>
    <p>
      <span className="fas fa-quote-left quote mr-2" />
      <Trans>Hello, I’m sorry that this has happened to you. Let me investigate and check the security of your account so this doesn’t happen again.</Trans><br/>
      <Trans>Can you confirm the last 4 digits of your card number, access number, limit, and balance. Bank staff are under investigation about this, so don’t let your bank know until we have concluded our investigation.</Trans><br/>
      <Trans>Now I need you to download TeamViewer so I can help you, as this can only be done via internet banking.</Trans>
      <span className="fas fa-quote-right quote ml-2" />
    </p>
    <p>
      <small><Trans>You know these two transactions weren’t yours so how would you respond?</Trans></small>
    </p>
  </div>
  )


  export const PersonalQuestionFourScenario = (
    <Fade bottom>
      <ContentMessage value={CallMessageContent} />
    </Fade>
  )