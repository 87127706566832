
import { Trans } from 'react-i18next'
import { EmailMessage } from '../../../BNZStepCommon'
import illustration from '../../../../../../assets/images/proof-of-payment-attached.svg'
import download from '../../../../../../assets/images/icon-download-all.png'
import preview from '../../../../../../assets/images/icon-preview-all.png'

const emailMessage = (
  <div>
    <div className="px-4 pt-3 pb-2">
      <span>
        <strong>
          <Trans>Sender:</Trans>
        </strong>{' '}
        accounts@gmail.com
      </span>
      <br />
      <span>
        <strong>
          <Trans>Reply to:</Trans>
        </strong>{' '}
        undisclosed-recipients
      </span>
      <br />
      <span>
        <strong>
          <Trans>To:</Trans>
        </strong>{' '}
        pisurber@gmail.com
      </span>
    </div>
    <hr />
    <div className="px-4 py-2">
      <Trans>Fwd: Notice of payment</Trans>
    </div>
    <div className="px-4 py-2">
      <img src={illustration} alt="" width="206" className="img-fluid" />
    </div>
    <div className="px-4 py-2">
      <span className="link mr-4">
        <img src={download} alt="" width="25" className="mr-2" />
        <small>
          <Trans>Download All</Trans>
        </small>
      </span>
      <span className="link">
        <img src={preview} alt="" width="21" className="mr-2" />
        <small>
          <Trans>Preview All</Trans>
        </small>
      </span>
    </div>
    <hr />
    <div className="px-4 py-4">
      <p className="color-grey">
        <Trans>Good morning,</Trans>
      </p>
      <p className="color-grey">
        <Trans>
          I have made payment of the invoice sent. I will wait until all the goods are been delivered to our office before making balance. Attached is
          proof of payment. Please confirm payment receipt and revert back to me.
        </Trans>
        <br />
        <Trans>Many thanks in advance.</Trans>
      </p>
      <p className="color-grey">
        <Trans>Kind Regards</Trans>
        <br />
        Anika
      </p>
    </div>
  </div>
)

export const BusinessQuestionFourScenario = <EmailMessage value ={emailMessage}/>
