import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import personal_en_NZ from "./locales/personal/en/translation.json";
import personal_teReo from './locales/personal/mi_NZ/translation.json'
import personal_smo from './locales/personal/smo/translation.json'
import personal_to from './locales/personal/to/translation.json'




import home_en_NZ from "./locales/home/en/translation.json";
import home_teReo from './locales/home/mi_NZ/translation.json'
import home_smo from './locales/home/smo/translation.json'
import home_to from './locales/home/to/translation.json'


import business_en_NZ from "./locales/business/en/translation.json";
import business_teReo from './locales/business/mi_NZ/translation.json'
import business_smo from './locales/business/smo/translation.json'
import business_to from './locales/business/to/translation.json'

const resources = {
  en: {
    home: home_en_NZ,
    personal : personal_en_NZ,
    business: business_en_NZ
  },
  mi_NZ: {
    home: home_teReo,
    personal: personal_teReo,
    business: business_teReo
  },
  smo: {
    home: home_smo,
    personal: personal_smo,
    business: business_smo
  },
  to: {
    home: home_to,
    personal: personal_to,
    business: business_to
  }
}

let lang = localStorage.getItem('currLang') || 'en'

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: {
      order: ['localStorage', 'navigator'] // order and from where user language should be detected
    },
 
    lng: lang,

    fallbackLng: "en", // use en if detected lng is not available

    debug: false,

    ns: ["home"],
    defaultNS: "home",

    nsSeparator: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  }, (err, t) => {
    if (err) return console.log('Something went wrong loading translations!', err);
})

export default i18n