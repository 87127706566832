import React, { Component } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { withTranslation, Trans } from 'react-i18next'
import illustration from '../../assets/images/illustration-karl-Impersonation.svg'

class BNZScamSavvyStepThree extends Component {
  render() {
    const content = (
      <section id="step3" className="step-three bg-pink">
      <div className="content-wrapper centre-it">
          <div className="row main-row">
            <div className="col-12 col-md-6 main-row-pic">
              <div className="image-content mx-auto ml-md-0 mb-5 mb-md-0">
                <Fade up>
                  <img src={illustration} width="438" className="img-fluid d-block mx-auto" />
                </Fade>
              </div>
            </div>
            <div className="col-12 col-md-6 main-row-body">
              <h2 className="color-white"><Trans>What is a scam?</Trans></h2>
              <div className="divider bg-white my-4 mx-auto ml-md-0" />
              <div className="intro-text color-white px-4 px-md-0">
                <Trans>Scams are deceptive, uninvited approaches designed to trick you into giving away your money, login credentials, payment card details, or personal information.</Trans>
                <br /><br />
                <Trans>People like Sam the Scammer will go out of their way to deceive you.</Trans>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepThree) )
