
import { Trans } from 'react-i18next'
import { IRDEmailMessage } from '../../../BNZStepCommon'
import ird from '../../../../../../assets/images/Logo_Inland_Revenue_Department.svg'

import i18n from '../../../../../../i18n'
//import Fade from 'react-reveal/Fade'


export const BusinessQuestionTwoScenario = <IRDEmailMessage/>
