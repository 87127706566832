import React from 'react'
import Fade from 'react-reveal/Fade'
import styles from './../bnz-scam-savvy-questions.scss'
import i18n from './../../../i18n';
import scammerSam from '../../../assets/images/illustration-sam-the-scammer.svg'
import badRabbit from '../../../assets/images/bad-rabbit.png'
import { Trans } from 'react-i18next'
import ird from '../../../assets/images/Logo_Inland_Revenue_Department.svg'

export function StepNumber(props) {
  return <div className="step-number">{i18n.t(props.value)}</div>
}

export function QuestionTitle(props) {
  return <div className="question-title">{props.value}</div>
}

export function ScammerPerson(props) {
  return (
    <img alt=""
      src={`images/scam/${props.value}.png`}
      className={`${styles.scammerImage}`} />
  )
}

export function LeftPanelContent(props) {
  return <div>{props.value}</div>
}

export function EmailMessageImage(props) {
  return (
    <div className="email-content-wrapper">
      <div className="email-header" />
      <div className="email-content">
        <img src={i18n.t(props.value)} width="100%" alt="" className="img-fluid" />
      </div>
    </div>
  )
}

export function EmailMessage(props) {
  return (
    <div className="email-content-wrapper">
      <div className="email-header" />
      <div className="email-content">{props.value}</div>
    </div>
  )
}

export function IRDEmailMessage(){
  return(<div className="email-content-wrapper">
  <div className="email-header" />
  <div className="email-content">
    <div>
      <div className="px-3 pt-3 pb-2">
        <span><strong><Trans>Sender:</Trans></strong> refunds@inlandrevenue.interlink.com</span>
        <br/>
        <span><strong><Trans>Reply to:</Trans></strong> refunds@inlandrevenue.interlink.com</span>
        <br/>
        <span><strong><Trans>To:</Trans></strong> jana.depolo@xtra.co.nz</span>
      </div>
      <hr/>
      <div className="px-3 py-2">
        <strong><Trans>Your Income Tax Update: July 2019. DO NOT REPLY</Trans></strong>
      </div>
      <hr/>
      <div className="px-5 py-4">
        <img src={ird} alt="ird" width="171" className="img-fluid mb-3" />
        <img src={i18n.t('/locale/images/ird-edm-header.jpg')} alt='' className='img-fluid mb-3' />
        <p className="color-grey">
          <strong><Trans>Dear <span className="link">jana.depolo@xtra.co.nz</span>,</Trans></strong>
        </p>
        <p className="color-grey">
          <Trans>You are eligible to receive a refund of $319.27 NZD.</Trans>
          <br/>
          <Trans>You have GST returns for a period ending 1 July 2018, due 31 July 2019, now available for refund!</Trans>
          <br/>
          <strong><Trans>Remember:</Trans></strong> <Trans>We tried to send it to you automatically but were unable to do so as we don’t have your details on file.</Trans>
        </p>
        <span className="link d-block pt-2 mb-4">
          <strong><Trans>Ready to refund it now?</Trans></strong>
        </span>
        <ul className="color-grey pl-3">
          <li><Trans>Have your credit/debit card ready.</Trans></li>
        </ul>
      </div>
    </div>
  </div>
</div>
)
}
  


export function TextMessage(props) {
  return (
    <Fade bottom>
      <div className="textmsg-content-wrapper">
        <div className="textmsg-content">{props.value}</div>
      </div>
    </Fade>
  )
}

export function SmartPhoneMessage(props) {
  return (
    <Fade bottom>
      <div className="smartphone-content-wrapper">
        <div className="smartphone-content">
          <img src={i18n.t(props.value)} alt="" width={props.width} className="img-fluid" />
        </div>
      </div>
    </Fade>
  )
}

export function ContentMessage(props) {
  return (
    <Fade bottom>
      <div className="contentmsg-wrapper">
        <div className="contentmsg-content">{props.value}</div>
      </div>
    </Fade>
  )
}

export function AdjustRender(rightPanelHeight, leftPanelHeight) {
  const right = document.getElementById('rightAppContainer')
  const left = document.getElementById('leftAppContainer')

  if (right !== null && left !== null) {

    left.style.removeProperty('visibility')
    right.style.removeProperty('visibility')
  }
}




export const ScammerSelector = ({ scammerId }) => {
  const scammer = {sam: scammerSam, rabbit: badRabbit };
  const selectedScammer = scammer[scammerId];

  if (!selectedScammer) {
    return null; 
  }

  return (
    <div className="image-content">
    <img className="animated slideInUp img-fluid" src={selectedScammer} width="277" alt = "scammer" />
  </div>
  );
};
