import React, { Component } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { withTranslation, Trans } from 'react-i18next'
import sam from '../../../assets/images/illustration-sam-the-scammer.svg'

class BNZScamSavvyStepTwo extends Component {
  render() {
    const content = (
      <section id="step2" className="step-two">
        <div className="content-wrapper centre-it">
          <div className="row main-row">
            <div className="col-12 col-md-7 main-row-body">
              <h2 className="color-dark-blue"><Trans>Meet Sam the Scammer</Trans></h2>
              <div className="divider bg-dark-blue my-4 mx-auto ml-md-0" />
              <div className="intro-text px-4 px-md-0">
                <Trans>He features throughout Scam Savvy and his job is to try and scam you without getting caught. Sam the Scammer represents the importance of looking at the finer details.</Trans>
              </div>
            </div>
            <div className="col-12 col-md-5 main-row-pic">
              <div className="image-content mx-auto mb-5 mb-md-0">
                <Fade up>
                  <img src={sam} width="277" className="img-fluid d-block mx-auto" alt=""/>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepTwo))
