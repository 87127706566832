import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import './sub-header.scss'
import scamSavvyLogo from './../../assets/images/home-scam-savvy-logo.gif'
import imgArrowDownWhite from './../../assets/images/icon-arrow-down-white.svg'

class SubHeader extends Component {

  render() {
    let content = (
        <div className="row header-options">
          <div className="col">
              <img src={scamSavvyLogo} alt="Scam Savvy" className="img-scam-savvy mb-4" width="403" />
              <h1 className="headline"><Trans>Helping New Zealanders feel safer online</Trans></h1>
              <p className="sub-heading-sub-header mt-4 mt-md-3"><Trans>Try our tools designed to help protect you from scams.</Trans></p>
              <div className="down-arrow">
                <img src={imgArrowDownWhite} class="sub" alt="" width="21" />
              </div>
          </div>
        </div>
      )
    return content
  }
}

export default SubHeader