import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { NextButton, RestartButton, BackButton, ReadButton } from '../shared/buttons/BNZScamSavvyButtons'
import Alert from '../shared/AlertPopup'
import { StepNumber, AdjustRender } from './shared/BNZStepCommon'
import BNZScamSavvyStepZero from '../steps/BNZScamSavvyStepZero'
import BNZScamSavvyPersonalResultsZero from '../results/personal/BNZScamSavvyResultsZero'
import BNZScamSavvyBusinessResultsZero from '../results/business/BNZScamSavvyResultsZero'
import { clearAllAnswers, setCurrentQuestion, showRestartPopup, increaseCurrentStep, decreaseCurrentStep, sendGA, decrementQuestion } from './shared/state/actions'
import { isMobile } from '../shared/helpers'
//import data from './shared/QuestionAssets/PersonalQuestionaire.json'

import LeftPanel from './shared/QuestionAssets/LeftPanel'
import {RightPanel} from './shared/QuestionAssets/RightPanel'
import { Route, useLocation } from 'react-router-dom'

class BNZQuestionStart extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      left: 0,
      right: 0,
      mobileFakeForward: '',
      isMobileQuestion: '',
      
    }
    this.onNext = this.onNext.bind(this)
    this.onPrev = this.onPrev.bind(this)
    this.onRestart = this.onRestart.bind(this)
    this.onFakeBack = this.onFakeBack.bind(this)
    this.onFakeForward = this.onFakeForward.bind(this)
    this.onAlertCancel = this.onAlertCancel.bind(this)
    this.onAlertDone = this.onAlertDone.bind(this)
    
    
  }

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    // this.forceUpdate()
  }

  componentDidUpdate() {
    if (window.innerWidth >= 767.99) {
      const right = document.getElementById('rightAppContainer')
      const left = document.getElementById('leftAppContainer')

      if (right !== null && left !== null && this.state.left !== 0 && this.state.right !== 0) {
        right.style.visibility = 'hidden'
        left.style.visibility = 'hidden'
        AdjustRender(this.state.right, this.state.left)
      }
    }
  }

  onNext() {
    this.props.dispatch(increaseCurrentStep())
    this.setState({ mobileFakeForward: '' })
    this.setState({ isMobileQuestion: '' })
    if (this.isValidStep()) {
      let type
      if (this.isScenario()) type = 'Next_'
      if (this.isAnswer()) type = 'ResultNext_'
      this.props.dispatch(sendGA('Click', `PersonalQuestion_${this.getStepNumberNumeric()}`, type + this.getStepNumberNumeric()))
    }
    if (isMobile) window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  onPrev() {
    if(this.props.answerStore.currentStep > 0){
      this.props.dispatch(decreaseCurrentStep())
    }else{
      this.props.dispatch(decrementQuestion())
    }
    
    this.setState({ mobileFakeForward: '' })
    this.setState({ isMobileQuestion: '' })
    if (this.isValidStep()) {
      this.props.dispatch(sendGA('Click', `PersonalQuestion_${this.getStepNumberNumeric()}`, 'Back'))
    }
    if (isMobile) window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  onRestart() {
    this.props.dispatch(showRestartPopup(true))

    if (this.isValidStep()) {
      this.props.dispatch(sendGA('Click', `PersonalQuestion_${this.getStepNumberNumeric()}`, 'PersonalExit'))
    } else {
      this.props.dispatch(sendGA('Click', 'PersonalScamSavvy', 'PersonalExit'))
    }
  }

  isValidStep() {
    const currentStep = this.props.answerStore.currentStep
    return currentStep >= 0 && currentStep < Object.keys(this.props.questionData).length
  }

  getStepNumber() {
    return this.isValidStep() ? "Q "+this.props.answerStore.questionNumber+"/"+Object.keys(this.props.questionData).length : null
  }

  getStepNumberValue() {
    return this.isValidStep() ? Object.keys(this.props.questionData)[this.props.answerStore.questionNumber-1] : null
  }

  getStepNumberNumeric() {
    return this.props.answerStore.questionNumber
  }
  //Refactor: doing it this way due to time constraint, not enough time to dig into the css
  getClazz() {

    let number = this.getStepNumberNumeric() === 4 ? `${this.props.questionData[this.getStepNumberValue()].QuestionId}` : (this.getStepNumberNumeric() === 5 ? `${this.props.questionData[this.getStepNumberValue()].QuestionId}` : (this.getStepNumberNumeric() === 6) ? `${this.props.questionData[this.getStepNumberValue()].QuestionId}`: "")
    return this.isScenario() ? `scenario-container scenario-${number}-container` : (this.isQuestion() ? `question-container question-${number}-container` :(this.isAnswer() ? `answer-container answer-${number}-container`: null))
  }

  isScenario() {
    return this.isValidStep()  ?  this.props.answerStore.isScenario : false
  }

  isQuestion() {
    return this.isValidStep() ? this.props.answerStore.isQuestion : false
  }

  isAnswer() {
    return this.isValidStep() ? this.props.answerStore.isAnswer : false
  }

  isDisabled() {
    const check = this.isValidStep() ? this.isQuestion() : null
    const stepNumber = this.isValidStep() ? this.getStepNumberValue() : null
    const current = this.props.answerStore.currentQuestion
    if (check && Object.keys(current).length === 0) return true

    if (check && Object.keys(current).length !== 0) {
      return current.questionNumber !== stepNumber
    }

    return false
  }

  onFakeForward() {
    this.setState({ mobileFakeForward: 'mobile-fake-forward-active' })
    this.setState({ isMobileQuestion: 'mobile-question' })
    if (isMobile()) window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  onFakeBack() {
    this.setState({ mobileFakeForward: 'mobile-fake-forward-been-there' })
    this.setState({ isMobileQuestion: '' })
    if (isMobile) window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  onAlertCancel() {
    this.props.dispatch(showRestartPopup(false))
  }

  onAlertDone() {
    this.props.dispatch(showRestartPopup(false))

    if (this.isValidStep()) {
      this.props.dispatch(sendGA('Click', `PersonalQuestion_${this.getStepNumberNumeric()}`, 'PersonalConfirmExit'))
    } else {
      this.props.dispatch(sendGA('Click', 'PersonalScamSavvy', 'PersonalConfirmExit'))
    }

    this.props.dispatch(clearAllAnswers())
    this.props.dispatch(setCurrentQuestion({}))
  }

  getColumnClazz() {
    let col = {
      col1: 'col-md-6',
      col2: 'col-md-6 bg-light-cyan read-through',
    }
    if (this.isQuestion())
      col = {
        col1: 'col-md-6 bg-light-cyan question-left-part',
        col2: 'col-md-6',
      }
    if (this.isAnswer())
      col = {
        col1: 'col-md-6 bg-light-cyan answer-left-part',
        col2: 'col-md-6',
      }

    return col
  }
  x

  render() {
    const stepNumber = this.getStepNumber()
    const clazz = this.getClazz()
    const disabled = this.isDisabled()
    const columnClazz = this.getColumnClazz()
    const mobileQuestionProgress = stepNumber ? stepNumber.replace('Q', 'Q - ') : ''
    const questionTransition = this.props.answerStore.questionFinishing === true ? ' question-transition ' : ''
    const questionCopyTransition = this.props.answerStore.questionCopyFinishing === true ? ' question-transition-copy ' : ''

    const answerTransition = this.props.answerStore.answerFinishing === true ? ' answer-transition ' : ''
    const answerCopyTransition = this.props.answerStore.answerCopyFinishing === true ? ' answer-transition-copy ' : ''
    
    let content = (
      <div className="col-sm-12">
        <Alert
          title="Exit Scam Savvy Personal"
          subtitle="Note: your progress will not be saved. Do you still wish to exit?"
          buttonLeftText="Cancel"
          buttonText="Yes"
          onCancel={this.onAlertCancel}
          onDone={this.onAlertDone}
          hidden={!this.props.answerStore.alertExitPopupVisible}
        />
        <div
          className={`row qa-base-container ${this.state.mobileFakeForward} ${questionTransition} ${questionCopyTransition} ${answerTransition} ${answerCopyTransition}`}
        >
          <div className={`${columnClazz.col1}`}>
            <div className={`row header-question-mobile ${this.state.isMobileQuestion}`}>
              <div className="col">
                <BackButton className="py-0" onClick={this.onPrev} />
              </div>
              <div className="col">
                <StepNumber value={mobileQuestionProgress} />
              </div>
              <div className="col text-right">
                <RestartButton onClick={this.onRestart} />
              </div>
            </div>

            <div className="back-button-wrapper hidden-on-mobile">
              <BackButton onClick={this.onPrev} />
            </div>

            <div id="leftAppContainer" className={`app-container leftc col-sm-12 float-right ${clazz} ${this.state.isMobileQuestion}`}>
              <div className="row">
                <div className="col-sm-12 left-panel-container">
                  <Fade right>
                    <LeftPanel questionNumberValue={this.getStepNumberValue()} step={this.props.answerStore.currentStep} questionData={this.props.questionData} />
                    <div className="btn-read-more-wrapper">
                      <ReadButton onClick={this.onFakeForward} />
                      <NextButton onClick={this.onNext} disabled={disabled} />
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <div className={`${columnClazz.col2}`}>
            <div className="restart-button hidden-on-mobile">
              <div className="divider top" />
              <StepNumber value={stepNumber} />
              <RestartButton onClick={this.onRestart} />
            </div>
            <div id="rightAppContainer" className={`app-container rightc col-sm-12 float-left ${clazz}`}>
              <div className="row">
                <div className="col-sm-12 right-panel-container">
                  <RightPanel questionNumberValue={this.getStepNumberValue()} step={this.props.answerStore.currentStep} questionData={this.props.questionData}/>
                  <div className="fake-back-btn-wrapper">
                    <BackButton onClick={this.onFakeBack} />
                    <NextButton onClick={this.onNext} disabled={disabled} />
                  </div>
                </div>
              </div>
            </div>
            <Fade up>
              <div className="next-button hidden-on-mobile">
                <NextButton onClick={this.onNext} disabled={disabled} />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
    if (this.props.answerStore.currentStep < 0) content = <BNZScamSavvyStepZero scroll={true} />
    

    if (this.getStepNumberNumeric() > Object.keys(this.props.questionData).length) content = (window.location.pathname.toString() ==="/scam-savvy-business/") ? <BNZScamSavvyBusinessResultsZero /> : <BNZScamSavvyPersonalResultsZero />
    return content
  }
}

export default withTranslation()(
  connect((state) => ({
    answerStore: state['bnz-scam-savvy-answer-list'],
  }))(BNZQuestionStart)
)
