export const pushSelectedAnswer = (selectedAnswer) => ({
  type: 'PUSH_SELECTED_ANSWER',
  selectedAnswer,
})

export const clearAllAnswers = () => ({
  type: 'CLEAR_ALL_ANSWERS',
})

export const setCurrentQuestion = (question) => ({
  type: 'SET_CURRENT_QUESTION',
  question,
})

export const updateCurrentStep = (value) => ({
  type: 'UPDATE_CURRENT_STEP',
  value,
})

export const showRestartPopup = (value) => ({
  type: 'UPDATE_RESTART_POPUP',
  value,
})

export const increaseCurrentStep = () => ({
  type: 'INCREASE_CURRENT_STEP',
})

export const decreaseCurrentStep = () => ({
  type: 'DECREASE_CURRENT_STEP',
})

export const initSteps = () => ({
  type: 'INIT_STEPS',
})

export const finishAnimation = () => ({
  type: 'ANIMATION_FINISHED',
})

export function sendGA(action, category, label) {
  return { type: 'SEND_GA_EVENT', action, category, label }
}

export function markAsDone(value) {
  return { type: 'MARK_STEP_AS_STARTED_FINISHING', value }
}
export function markAsUnDone(value) {
  return { type: 'MARK_STEP_AS_FINISHED_FINISHING', value }
}
export function markAsContentChanged(value) {
  return { type: 'MARK_STEP_AS_COPY_CHANGED', value }
}

export function incrementQuestion(value) {
  return { type: 'INCREMENT_QUESTION', value }
}

export function decrementQuestion(value) {
  return { type: 'DECREMENT_QUESTION', value }
}

export function setStepNumber(value){
  return { type: 'SET_STEP_NUMBER', value }
}
