import React from 'react'
import { Trans } from 'react-i18next';
import i18n from '../../../i18n';

export function RestartButton(props) {
  return (
    <a className="btn" onClick={props.onClick}>
      <i className="fas fa-times" />
    </a>
  )
}

export function BackButton(props) {
  return (
    <a className="btn-back-button" onClick={props.onClick}>
      <i className="fas fa-chevron-left" /> <Trans>Back</Trans>
    </a>
  )
}

export function ReadButton(props) {
  return (
    <button
      className="btn btn-primary btn-read-more"
      onClick={props.onClick}
      disabled={props.disabled}>
      <Trans>Take a read</Trans>
    </button>
  )
}

export function NextButton(props) {
  return (
    <button
      className="btn btn-primary btn-next-button"
      onClick={props.onClick}
      disabled={props.disabled}>
      <Trans>Next &gt;</Trans>
    </button>
  )
}

export function NextQuestionButton(props) {
  const value = props.children || 'Next question'
  return (
    <button
      className="btn btn-primary btn-next-question-button"
      onClick={props.onClick}>
      {i18n.t(value)}
    </button>
  )
}

export function LearnMore(quizType){
  if(quizType==="personal"){
    return <button
      className="btn btn-primary btn-next-question-button"
      onClick="">
      <span className="mr-2"><Trans>Learn more &gt;</Trans></span>
    </button>
  }else{
    return <button
    type="buttton"
      className="btn btn-outlined mx-auto mt-5"
      onClick="">
      <span className="mr-2"><Trans>Learn more &gt;</Trans></span>
    </button>
  }
}