import TagManager from 'react-gtm-module'
import './i18n'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'

import {BrowserRouter as Router} from 'react-router-dom'
import BNZScamSavvyQuestionListReducer from './components/questions/shared/state/reducer'
import App from './App'

import './styles/base.scss'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_PROPERTY,
  dataLayerName: 'PageDataLayer',
}

TagManager.initialize(tagManagerArgs)

const dev = process.env.NODE_ENV !== 'production'
const createStoreWithMiddleware = compose(dev ? applyMiddleware(createLogger()) : (f) => f)(createStore)

const store = createStoreWithMiddleware(
  combineReducers({
    'bnz-scam-savvy-answer-list': BNZScamSavvyQuestionListReducer,
  })
)

ReactDOM.render(<Provider store={store}><Router><App /></Router></Provider>, document.getElementById('root'))
