// @flow

import React from 'react'
import i18n from '../../i18n'
import styles from './alert-popup.module.scss'

export default function Alert({
  title,
  subtitle,
  buttonLeftText,
  buttonText,
  onCancel,
  onDone,
  hidden
}) {
  return (
    <div className={styles.alertWrapper} hidden={hidden}>
      <div className={styles.alert}>
        <h1 className="mb-4">{i18n.t(title)}</h1>
        <p className="mb-4">{i18n.t(subtitle)}</p>

        <div className="flex">
          {buttonLeftText && (
            <a
              href="#"
              onClick={e => onCancel(e)}
              className={`${styles.btn} ${styles.btnLeft}`}>
              {i18n.t(buttonLeftText)}
            </a>
          )}

          {buttonText && (
            <a href="#" onClick={e => onDone(e)} className={styles.btn}>
              {i18n.t(buttonText)}
            </a>
          )}
        </div>
      </div>
      <div className={styles.overlay}>&nbsp;</div>
    </div>
  )
}
