import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import scamSavvyLogo from '../../../assets/images/scam-savvy-logo.gif'
import iconArrowDownWhite from '../../../assets/images/icon-arrow-down-white.svg'
import businesses from '../../../assets/images/businesses.svg'

class BNZScamSavvyStepOne extends Component {
  render() {
    const content = (
      <section id="step1" className="step-one-business">
        <div className="top w-100">
        <div className="content-wrapper centre-it">
            <img className="d-none d-md-block mt-5 pt-lg-4 mx-auto" src={scamSavvyLogo} width="403" alt="" />
            <img className="img-business mt-5" src={businesses} width="1144" alt="" />
          </div>
        </div>
        <div className="bottom w-100 bg-purple">
          <div className="content-wrapper centre-it">
            <div className="intro-text text-center mx-auto color-white">
              <div className="p-4">
                <h2 className="color-white mx-auto" style={{ maxWidth: '620px' }}><Trans>Helping New Zealand businesses be safer online.</Trans></h2>
                <br />
                <div>
                  <Trans>Scam Savvy is a tool designed to help you and your business identify and avoid scams, to be safer online.</Trans>
                </div>
              </div>
              <button className="btn btn-primary btn-white d-block mx-auto mt-5" onClick={() => this.props.onClickStart()}>
                <Trans>Start the quiz &gt;</Trans>
              </button>
              <div className="down-arrow">
                <img src={iconArrowDownWhite} alt="" width="21" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()(connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepOne))
