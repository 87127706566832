import { Trans } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import {DynamicSVG} from './DynamicSvg'
import { ScammerSelector } from '../BNZStepCommon'

 export default function LeftPanel({questionNumberValue, step, questionData}){
  let content;
  switch(step){
    case 0:
      content = 
    <div id={`leftPanelContent`} className="left-panel-content">
      <Fade>
        <div>
          <h1>
            <Trans>{questionData[questionNumberValue].Scenario.Heading}</Trans>
          </h1>
          <h4>
            <Trans>{questionData[questionNumberValue].Scenario.SubHeading}</Trans>
          </h4>
          <div className="divider" />
          <div />
          
            {questionData[questionNumberValue].Scenario.Body!=null ?
            <p><Trans>{questionData[questionNumberValue].Scenario.Body}</Trans></p>:<></>}

          {questionData[questionNumberValue].Scenario.Body2!=null ?
          <p>
          <Trans>{questionData[questionNumberValue].Scenario.Body2}</Trans>
          
        </p> :<></> }
        </div>
      </Fade>
    </div>
    break
    case 1:
      content = <DynamicSVG id={questionData[questionNumberValue].QuestionId}/>
      break
    case 2:
      content = 
      <ScammerSelector scammerId={questionData[questionNumberValue].ScammerId}/>

    break
    default:
      content=<></>
  }
  return(content);
}
