import React, { Component } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import {
  pushSelectedAnswer,
  setCurrentQuestion,
  increaseCurrentStep,
  sendGA,
  markAsDone,
  markAsUnDone,
  markAsContentChanged
} from './state/actions'
import { isMobile } from '../../shared/helpers'

class BNZQuestionList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: [false, false, false]
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  predicate(event, element) {
    return event.target.value === element.id
  }

  UNSAFE_componentWillMount() {
    const selectedAnswers = this.props.answerStore.selectedAnswers
    const checked = []
    this.props.value.forEach(choice => {
      const selected = this.isSelected(choice, selectedAnswers)
      if (selected) {
        this.props.dispatch(setCurrentQuestion(choice))
      }
      checked.push(selected)
    })
    this.setState({ checked })
  }

  isSelected(choice, selectedAnswers) {
    return selectedAnswers.findIndex(current => current.id === choice.id) !== -1
  }

  handleChange(event) {
    const questions = this.props.value
    const checked = questions.map(element => this.predicate(event, element))
    const selectedAnswer = questions.find(element =>
      this.predicate(event, element)
    )
    selectedAnswer.questionNumber = this.props.questionNumber

    this.setState({ checked })
    this.props.dispatch(pushSelectedAnswer(selectedAnswer))
    this.props.dispatch(setCurrentQuestion(selectedAnswer))
    this.track(
      selectedAnswer.questionNumber.replace('Q', ''),
      event.target.value
    )
  }

  handleClick() {
    setTimeout(() => {
      this.props.dispatch(markAsDone('question'))
      setTimeout(() => {
        this.props.dispatch(increaseCurrentStep())
        this.props.dispatch(markAsContentChanged('question'))
        setTimeout(() => {
          this.props.dispatch(markAsUnDone('question'))
        }, 1700)
      }, 100)
    }, 50)
    if (isMobile) window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  clazz(question) {
    // requirement change, everything is a tick now
    return 'fas '.concat(question.correct ? 'fa-check' : 'fa-check')
  }

  track(question, id) {
    let option
    if (id.indexOf('C1') >= 0) option = 'a'
    if (id.indexOf('C2') >= 0) option = 'b'
    if (id.indexOf('C3') >= 0) option = 'c'
    this.props.dispatch(
      sendGA('Click', `PersonalQuestion_${question}`, `Answer_${option}`)
    )
  }

  delayer(index) {
    return index * 500
  }

  shuffleQuestions(array) {

    // Stop shuffle if question has been answered
    if ( this.state.checked.includes(true) ) return array;

    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  render() {
    const shuffled = this.shuffleQuestions(this.props.value);
    const choices = shuffled.map((question, index) => (
      <Fade key={question.id} bottom delay={this.delayer(index + 1)}>
        <label className={`valid-${question.correct}`}>
          <div
            className="question-text"
            dangerouslySetInnerHTML={{ __html: i18n.t(question.question) }} />
          <div className="question-choice">
            <input
              type="radio"
              name="questions"
              onClick={this.handleClick}
              onChange={this.handleChange}
              value={question.id}
              checked={this.state.checked[index]} />
            <span className="checkmark">
              {this.state.checked[index] && (
                <i className={this.clazz(question)} />
              )}
            </span>
          </div>
        </label>
      </Fade>
    ))
    return <div>{choices}</div>
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZQuestionList))
