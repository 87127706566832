import { Trans } from 'react-i18next'
import illustration from '../../../../../../assets/images/illustration-lueur.svg'
import Fade from 'react-reveal/Fade'
import { EmailMessage } from '../../../BNZStepCommon'



const RightPanelTextScenarioTwo= (
    <div className="p-4">
      <div className="bg-gold w-100 text-center p-3 mb-4">
        <img src={illustration} alt="" width="181" />
      </div>
      <p className="color-grey">
        <Trans>Lueur has been available in Europe, Asia and North America for some time and we are coming to New Zealand for the first time soon! However, COVID-19 has slowed our plans down but we don’t want you to miss out. So in advance of our full launch in Aotearoa, we are excited to be able to offer a free product sample of Lueur.</Trans>
      </p>
      <p className="color-grey mb-4">
        <Trans>This pack comes at a value of $29.99 and all you will need to do is pay for shipping at $7.95. <span className="link">Click here</span> to apply for this limited time offer.</Trans>
      </p>
      <hr/>
      <div className="row mt-3">
        <div className="col text-center color-grey"><Trans>Like</Trans></div>
        <div className="col text-center color-grey"><Trans>Comment</Trans></div>
        <div className="col text-center color-grey"><Trans>Share</Trans></div>
      </div>
    </div>
  )


  export const PersonalQuestionTwoScenario = 
  (<Fade bottom>
  <EmailMessage value={RightPanelTextScenarioTwo} />
  </Fade>)

