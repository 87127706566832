
import { Trans } from 'react-i18next'
import { ContentMessage } from '../../../BNZStepCommon'
//import Fade from 'react-reveal/Fade'

const emailMessage=(
    <div>
    <p>
      <span className="fas fa-quote-left quote mr-2" />
      <Trans>Hello, my name is Paul,</Trans><br/>
      <Trans>I am a technical support engineer calling from your broadband provider. Our virus detection unit has found that your computer has a virus. But with a few simple over the phone steps, I can help you remove the virus.</Trans>
      <span className="fas fa-quote-right quote ml-2" />
    </p>
  </div>
)

export const BusinessQuestionThreeScenario = <ContentMessage value ={emailMessage}/>
