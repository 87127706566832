import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import icon from '../../assets/images/icon-arrow-down.svg'
import logo from '../../assets/images/scam-savvy-logo.gif'

class BNZScamSavvyStepOne extends Component {

  render() {
    const content = (
      <section id="step1" className="step-one bg-teal">
        <div className="content-wrapper centre-it">
          <img className="img-scam-savvy" src={logo} width="403" />
          <div className="intro-text text-center mx-auto color-dark-blue">
            <div className="p-4">
              <Trans>Scam Savvy is designed to help you identify and avoid scams and be safer online.</Trans>
              <br /><br />
              <Trans>It will teach you how to identify scams, what to do when you identify them, and even what to do if you’ve been scammed.</Trans>
            </div>
            <button className="btn btn-primary d-block mx-auto mt-5" onClick={() => this.props.onClickStart() }>
              <Trans>Start the quiz &gt;</Trans>
            </button>
            <div className="down-arrow">
              <img src={icon} alt="" width="21" />
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepOne) )
