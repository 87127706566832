import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'

class BNZScamSavvyResultsThree extends Component {
  render() {
    const content = (
      <section id="results-three" className="results-three">
        <div className="content-wrapper centre-it">
          <h2 className="color-dark-blue text-center text-md-left ml-md-3 mb-5"><Trans>Signs that something might <span style={{whiteSpace:"nowrap"}}>be a scam</span></Trans></h2>
          <div className="intro-text">
            <ul>
              <li><Trans>You’ve been contacted out of the blue by someone you don’t know from a company you may or may not have heard of.</Trans></li>
              <li><Trans>The offer to your business seems too good to be true.</Trans></li>
              <li><Trans>The scammer wants you to act quickly.</Trans></li>
              <li><Trans>The scammer wants you to share business or financial information.</Trans></li>
              <li><Trans>The website isn’t secure and there are no contact details.</Trans></li>
              <li><Trans>The website contains links directing you to confirm information or log in to a service.</Trans></li>
              <li><Trans>The communication is poorly written and contains spelling and grammar mistakes.</Trans></li>
              <li><Trans>The scammer has asked you to keep the communication to yourself.</Trans></li>
              <li><Trans>The account number you are being asked to pay into is different from the usual account.</Trans></li>
            </ul>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( BNZScamSavvyResultsThree )
