import React, { Component } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { withTranslation, Trans } from 'react-i18next'
import screenshot from '../../assets/images/video-screenshot.png'

class BNZScamSavvyStepFive extends Component {
  render() {
    const content = (
      <section id="step5" className="step-five bg-purple">
      <div className="content-wrapper centre-it">
          <div className="row main-row">
            <div className="col-12 col-md-6">
              <div className="image-content mx-auto ml-md-0 mb-5 mb-md-0">
                <Fade up>
                  <img src={screenshot} alt="helping you get scam savvy" className="img-fluid d-block" onClick={() => {this.props.onClickVideo()}}/>
                </Fade>
              </div>
            </div>
            <div className="col-12 col-md-6 main-row-body">
              <h2 className="color-white"><Trans>See if you can spot a scam!</Trans></h2>
              <div className="divider bg-white my-4 mx-auto ml-md-0" />
              <div className="intro-text color-white px-4 px-md-0">
                <Trans>Want to know how to outsmart a scammer? Check out this video to see the tactics scammers are using, and what you can do protect you and your whānau.</Trans>
                  <button className="btn btn-primary btn-white d-block mt-5 mx-auto mx-md-0" onClick={() => {this.props.onClickVideo()} }>
                    <Trans>Watch the video &gt;</Trans>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepFive) )
