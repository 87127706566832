import logo from '../../../../../../assets/images/logo-netflix.svg'
import { Trans } from 'react-i18next'
import { EmailMessage } from '../../../BNZStepCommon'
//import Fade from 'react-reveal/Fade'

const emailMessage=(
  <div>
    <div className="px-3 pt-3 pb-2">
      <span>
        <strong>
          <Trans>Sender:</Trans>
        </strong>{' '}
        support@netflix.solutions
      </span>
      <br />
      <span>
        <strong>
          <Trans>To:</Trans>
        </strong>{' '}
        michaeljpaterson@gmail.com
      </span>
    </div>
    <hr />
    <div className="px-3 py-2">
      <strong>
        <Trans>Your Netflix membership is on hold!</Trans>
      </strong>
    </div>
    <div className="bg-black color-white px-3 py-4">
      <img src={logo} alt="Netflix" width="171" className="d-block mx-auto mb-4" />
      <p className="color-white">
        <Trans>Hi michaeljpaterson@gmail.com,</Trans>
      </p>
      <p className="color-white">
        <Trans>
          In 2020 having great content at your fingertips has never been more important. We’re taking the opportunity to bring more content to NetFlix
          NZ and are updating our app to ensure you are getting the best selection of our great shows.
        </Trans>
      </p>
      <p className="color-white">
        <Trans>
          The update will require you to reconfirm your billing details and ensure we have the correct payment details recorded on your account.
        </Trans>
      </p>
      <p className="color-white">
        <Trans>
          We don’t want you to miss out on the great international content coming to Netflix so note that failure to complete the account validation
          process within the next 24 hours will result in closure of your Netfl1x membership.
        </Trans>
      </p>
      <p className="color-white">
        <Trans>
          Obviously we’d love to have you back, simply click update my membership, enter your details and continue to enjoy all the best TV shows &
          movies without interruption.
        </Trans>{' '}
        <span className="link">
          <Trans>Update my membership</Trans>
        </span>
      </p>
      <p className="color-white mb-0">
        <strong>
          <Trans>Netflix Support Team</Trans>
        </strong>
      </p>
    </div>
  </div>
)

export const PersonalQuestionOneScenario = <EmailMessage value ={emailMessage}/>
