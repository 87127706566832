import React, { Component } from 'react'
import { connect } from 'react-redux'
import Reveal from 'react-reveal/Reveal'
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n'
import { NextQuestionButton } from '../../shared/buttons/BNZScamSavvyButtons'
import {
  increaseCurrentStep,
  sendGA,
  markAsContentChanged,
  markAsUnDone,
  markAsDone,
  incrementQuestion
} from './state/actions'
import { isMobile } from '../../shared/helpers'



class BNZAnswer extends Component {
  constructor(props) {
    super(props)
    this.onNext = this.onNext.bind(this)
  }

  onNext() {
    this.props.dispatch(markAsDone('answer'))
    setTimeout(() => {
      this.props.dispatch(increaseCurrentStep())
      this.props.dispatch(incrementQuestion())

      this.props.dispatch(markAsContentChanged('answer'))
    }, 200)
    setTimeout(() => {
      const num = this.props.answerStore.currentQuestion.questionNumber.replace('Q','')
      this.props.dispatch(markAsUnDone('answer'))
      this.props.dispatch(sendGA('Click', `PersonalQuestion_${num}`, `Result_${num}_Next`))
    }, 250)
    if (isMobile) window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  render() {
    const currentChoice = this.props.answerStore.currentQuestion
    const btnLabel = currentChoice.questionNumber.replace('Q','') == Object.keys(this.props.questionData).length ? 'See results' : 'Next question >'

    return (
      <div className="answer-card">
        <Reveal delay={400} effect="animated fadeIn">
          <div className="answer-title">{i18n.t(currentChoice.answerTitle)}</div>
          <div className="divider" />
          <div
            className="answer-text"
            dangerouslySetInnerHTML={{ __html: i18n.t(currentChoice.answer) }} />
          <NextQuestionButton onClick={this.onNext}>
            {btnLabel}
          </NextQuestionButton>
        </Reveal>
      </div>
    )
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZAnswer) )