import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'

class BNZScamSavvyResultsFour extends Component {
  render() {
    const content = (
      <section id="results-four" className="results-four bg-dark-blue">
        <div className="content-wrapper centre-it">
            <h2 className="color-white text-center pb-md-4"><Trans>What you should do if you think you’ve been scammed</Trans></h2>
            <div className="row mt-5 mb-md-5">
              <div className="col-12 col-md-4">
                <div className="report-card">
                  <div className="badge">1</div>
                  <Trans>Contact your bank as soon as possible to let them know what’s happened.</Trans>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="report-card">
                  <div className="badge">2</div>
                  <Trans>Your bank may need to help you reset passwords, monitor your account for unusual transactions, or reissue cards.</Trans>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="report-card pb-0 pb-md-3">
                  <div className="badge">3</div>
                  <Trans>If the scammers have got hold of any personal or business information, notify your IT support team or person, contact your local New Zealand Police station, and report the incident to <a href="https://www.cert.govt.nz/business/" target="_blank" rel="noopener noreferrer">Cert NZ</a>.</Trans>
                </div>
              </div>
            </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( BNZScamSavvyResultsFour )
