import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import { sendGA } from '../../../components/questions/shared/state/actions'
import logo from '../../../assets/images/acertnz-logo.svg'

class BNZScamSavvyResultsFive extends Component {
  
  constructor(props) {
    super(props)
    this.track = this.track.bind(this)
  }

  track() {
    this.props.dispatch(sendGA('Click', 'PersonalResults', 'ReportScam'))
  }

  render() {
    const content = (
      <section id="results-five" className="results-five bg-white">
        <div className="content-wrapper centre-it">
          <div className="btn-wrap text-center">
            <a className="btn btn-primary px-3 px-md-4 mb-4 mb-md-5" href="https://www.cert.govt.nz/individuals/report-an-issue/" target="_blank" rel="noopener noreferrer" onClick={this.track}>
              <Trans>Report an online scam ></Trans>
            </a>
            <img src={logo} alt="CERTNZ" className="d-block mx-auto" width="140" />
          </div>
        </div>
      </section>
    )
    return content
  }
}

export default withTranslation()( connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyResultsFive) )
